import { escape, makeEl } from './dom';
import PhotoSwipeLightbox from './vendor/photoswipe-lightbox.esm';
import PhotoSwipe from './vendor/photoswipe.esm';

function setupPhotoGallery() {
    if (document.querySelector('#gallery')) {
        new PhotoSwipeLightbox({
            gallery: '#gallery',
            children: 'a.gallery__attachment',
            pswpModule: PhotoSwipe
        }).init();
    }

    [].forEach.call(document.querySelectorAll('div[data-video-src]'), el => {
        el.addEventListener('click', e => {
            e.preventDefault();
            el.replaceWith(makeEl(
                `<video controls="controls">
                        <source src="${el.dataset.videoSrc}" />
                      </video>`
            ));
        })
    });
}

export { setupPhotoGallery };